import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { ORGANIZATION_SCHEMA } from "./ValidationSchema";
import { getYears, getDisplayDate } from "../lease/DateUtil";
import { LEGAL_STATUS_TYPES } from "../common/LookupConstants";
import Can from "../../auth/can";
import DatePicker from "react-datepicker";

const YEARS = getYears();

const getFinancialYears = () => {
  let year = new Date().getFullYear();
  const fy = [];
  for (let indx = 0; indx < 5; indx++) {
    fy.push(
      `${year - indx - 1}-${((year - indx) % 100).toString().padStart(2, "0")}`
    );
  }
  return fy;
};

const fnYear = getFinancialYears();

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const Phones = props => {
  const { values, errors, touched, readOnly } = props;

  const phones = values.phones || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name="phones"
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {phones.map((ph, phIndex) => {
              const phError = (errors.phones && errors.phones[phIndex]) || {};
              const phTouched =
                (touched.phones && touched.phones[phIndex]) || {};

              const hideRemoveBtn = phones.length === 1;
              const hideAddBtn = phones.length - 1 !== phIndex;
              return (
                <Fragment key={phIndex}>
                  <strong className="edit-page-label">Mobile</strong>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`input-group  ${
                          getError(phTouched, phError, "phone")
                            ? "is-invalid mb-0"
                            : "mb-3"
                        }`}
                      >
                        <Field
                          type="text"
                          className={`form-control form-control-sm w-25  ${
                            getError(phTouched, phError, "phone")
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="10 digits"
                          name={`phones[${phIndex}].phone`}
                          value={ph.phone || ""}
                          {...disabledField}
                        />
                        &nbsp;
                        <Field
                          component="select"
                          className={`custom-select custom-select-sm  ${
                            getError(phTouched, phError, "type")
                              ? "is-invalid"
                              : ""
                          }`}
                          name={`phones[${phIndex}].type`}
                          value={ph.type || ""}
                          {...disabledField}
                        >
                          <option value="">Select...</option>
                          <option value="MOBILE">Mobile</option>
                          <option value="WORK">Work</option>
                          <option value="OFFICE">Office</option>
                          <option value="OTHER">Other</option>
                        </Field>
                        &nbsp;
                        <button
                          className={`btn btn-info btn-sm  ${
                            hideAddBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add"
                          type="button"
                          onClick={() => push({ phone: "", type: "WORK" })}
                          {...disabledField}
                        >
                          <i className="fas fa-plus " />
                        </button>
                        &nbsp;
                        <button
                          className={`btn btn-outline-danger btn-sm  ${
                            hideRemoveBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          type="button"
                          onClick={() => remove(phIndex)}
                          {...disabledField}
                        >
                          <i className="far fa-trash-alt " />
                        </button>
                      </div>
                      <div
                        className={` ${
                          getError(phTouched, phError, "phone")
                            ? "invalid-feedback d-block mb-3"
                            : "d-none"
                        }`}
                      >
                        Please enter phone and type *
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Emails = props => {
  const { values, errors, touched, readOnly } = props;

  const emails = values.emails || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name="emails"
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {emails.map((em, emIndex) => {
              const emError = (errors.emails && errors.emails[emIndex]) || {};
              const emTouched =
                (touched.emails && touched.emails[emIndex]) || {};

              const hideRemoveBtn = emails.length === 1;
              const hideAddBtn = emails.length - 1 !== emIndex;
              return (
                <Fragment key={emIndex}>
                  <strong className="edit-page-label">Email</strong>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`input-group  ${
                          getError(emTouched, emError, "email")
                            ? "is-invalid mb-0"
                            : "mb-3"
                        }`}
                      >
                        <Field
                          type="email"
                          className={`form-control form-control-sm w-25  ${
                            getError(emTouched, emError, "email")
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="10 digits"
                          name={`emails[${emIndex}].email`}
                          value={em.email || ""}
                          {...disabledField}
                        />
                        &nbsp;
                        <Field
                          component="select"
                          className={`custom-select custom-select-sm  ${
                            getError(emTouched, emError, "type")
                              ? "is-invalid"
                              : ""
                          }`}
                          name={`emails[${emIndex}].type`}
                          value={em.type || ""}
                          {...disabledField}
                        >
                          <option value="">Select...</option>
                          <option value="MOBILE">Mobile</option>
                          <option value="WORK">Work</option>
                          <option value="OFFICE">Office</option>
                          <option value="OTHER">Other</option>
                        </Field>
                        &nbsp;
                        <button
                          className={`btn btn-info btn-sm  ${
                            hideAddBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add"
                          type="button"
                          onClick={() => push({ email: "", type: "WORK" })}
                          {...disabledField}
                        >
                          <i className="fas fa-plus " />
                        </button>
                        &nbsp;
                        <button
                          className={`btn btn-outline-danger btn-sm  ${
                            hideRemoveBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          type="button"
                          onClick={() => remove(emIndex)}
                          {...disabledField}
                        >
                          <i className="far fa-trash-alt " />
                        </button>
                      </div>
                      <div
                        className={` ${
                          getError(emTouched, emError, "email")
                            ? "invalid-feedback d-block mb-3"
                            : "d-none"
                        }`}
                      >
                        Please enter email and type *
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Addresses = props => {
  const { values, errors, touched, setFieldValue, readOnly } = props;

  const addresses = values.addresses || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={`addresses`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {addresses.map((address, addrIndex) => {
              const addrTouched =
                (touched.addresses && touched.addresses[addrIndex]) || {};
              const addrError =
                (errors.addresses && errors.addresses[addrIndex]) || {};

              const hideRemoveBtn = addresses.length === 1;
              const hideAddBtn = addresses.length - 1 !== addrIndex;
              return (
                <Fragment key={addrIndex}>
                  <div
                    className={`row no-gutters ${
                      addrIndex === 0 ? "" : "mt-5"
                    }`}
                  >
                    <div className="col-12 mb-3 edit-page-label">
                      <span> Address Information {addrIndex + 1} </span>
                    </div>
                  </div>

                  <div className="row mb-2">
                    {/* Street Line 1 */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Street Line 1
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "streetLine1")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].streetLine1`}
                            values={address.streetLine1 || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].streetLine1`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Street Line 2 */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Street Line 2
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "streetLine2")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].streetLine2`}
                            values={address.streetLine2 || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].streetLine2`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* City */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          City
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "city")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].city`}
                            values={address.city || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].city`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Landmark */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Landmark
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "landmark")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].landmark`}
                            values={address.landmark || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].landmark`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* State */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          State
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "state")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].state`}
                            values={address.state || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {props.states.map((state, i) => (
                              <option key={i} value={state.stateCode}>
                                {state.stateName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].state`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Country */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Country
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "country")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].country`}
                            values={address.country || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {props.countries.map((country, i) => (
                              <option key={i} value={country.countryCode}>
                                {country.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].country`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Postal Code */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Postal Code
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "postalCode")
                                ? "is-invalid"
                                : ""
                            }`}
                            inputMode="numeric"
                            name={`addresses[${addrIndex}].postalCode`}
                            values={address.postalCode || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].postalCode`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Address Type */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Address Type
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "type")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].type`}
                            values={address.type || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            <option value="PERMANENT">Permanent Address</option>
                            <option value="COMMUNICATION">
                              Communication Address
                            </option>
                            <option value="OFFICE">Office Address</option>
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].type`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Address Proof */}
                  <div className="row mb-2">
                    <div className="col-lg-12 mb-0">
                      <div className="form-group row mb-0">
                        <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label">
                          Address Proof (Driving License, Aadhaar)
                        </label>
                        <div className="col-lg-6">
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id={`addresses[${addrIndex}].uploadAddressProof`}
                                name={`addresses[${addrIndex}].uploadAddressProof`}
                                onChange={e => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setFieldValue(
                                      `addresses[${addrIndex}].uploadAddressProof`,
                                      file
                                    );
                                  }
                                }}
                                {...disabledField}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor={`addresses[${addrIndex}].uploadAddressProof`}
                              >
                                {address.uploadAddressProof
                                  ? address.uploadAddressProof.name
                                  : "Choose file"}
                              </label>
                            </div>
                            <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-outline-info"
                                type="button"
                                onClick={e =>
                                  setFieldValue(
                                    `addresses[${addrIndex}].uploadAddressProof`,
                                    ""
                                  )
                                }
                                {...disabledField}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          {address.addressProof ? (
                            <button
                              type="button"
                              className="btn btn-info btn-sm mt-1"
                              onClick={() => {
                                if (address.addressProof) {
                                  const id = address.addressProof._id;
                                  props.downloadDocument(
                                    `${values._id}/address/${address._id}/${id}`
                                  );
                                }
                              }}
                            >
                              <i className="fas fa-download mr-1" /> Address
                              Proof
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2 mb-0">
                    <div className="col-12">
                      <button
                        className={`btn btn-outline-info btn-sm ${
                          hideAddBtn ? "d-none" : ""
                        }`}
                        type="button"
                        onClick={() =>
                          push({
                            uploadAddressProof: "",
                            addressProof: "",
                            streetLine1: "",
                            streetLine2: "",
                            landmark: "",
                            city: "",
                            state: "TN",
                            postalCode: "",
                            country: "IN",
                            status: "",
                            type: ""
                          })
                        }
                        {...disabledField}
                      >
                        Add More
                      </button>
                      &nbsp;
                      <button
                        className={`btn btn-outline-danger btn-sm ${
                          hideRemoveBtn ? "d-none" : ""
                        }`}
                        type="button"
                        onClick={() => remove(addrIndex)}
                        {...disabledField}
                      >
                        Remove Address {addrIndex + 1}
                      </button>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const ProductsService = props => {
  const { values, errors, touched, readOnly } = props;

  const productErrors = errors.products || [];
  const productTouched = touched.products || [];

  const products = values.products || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <div className="card-body">
      <FieldArray
        name="products"
        render={({ insert, remove, push }) => (
          <Fragment>
            {products.map((product, index) => {
              const prdError = productErrors[index] || {};
              const prdTouch = productTouched[index] || {};

              return (
                <Fragment key={index}>
                  {/*Product Name */}
                  <div className={`row mb-2 ${index !== 0 ? "mt-5" : ""}`}>
                    <div className="col-lg-8">
                      <div className="form-group row mb-0">
                        <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                          Product Name
                        </label>
                        <div className="col-lg-9">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(prdTouch, prdError, "name")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`products[${index}].name`}
                            values={product.name || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`products[${index}].name`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {products.length > 1 ? (
                        <button
                          className="btn btn-sm btn-outline-danger"
                          type="button"
                          onClick={() => remove(index)}
                          {...disabledField}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  </div>

                  {/*Product Description */}
                  <div className="row mb-2">
                    <div className="col-lg-8">
                      <div className="form-group row mb-0">
                        <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                          Product Description
                        </label>
                        <div className="col-lg-9">
                          <Field
                            component="textarea"
                            className={`form-control form-control-sm ${
                              getError(prdTouch, prdError, "description")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`products[${index}].description`}
                            values={product.description || ""}
                            rows="10"
                            style={{ resize: "none" }}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`products[${index}].description`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}

            <div className="row mb-2">
              <div className="col-lg-2 " />
              <div className="col-lg-6">
                <button
                  className="btn btn-sm btn-outline-info"
                  type="button"
                  onClick={() =>
                    push({
                      name: "",
                      description: ""
                    })
                  }
                  {...disabledField}
                >
                  Add More
                </button>
              </div>
            </div>
          </Fragment>
        )}
      />
    </div>
  );
};

const CustomForm = props => {
  const { values, errors, touched, setFieldValue, readOnly } = props;

  const identificationDocs = values.identificationDocs || {};

  const identificationDocsTouched = touched.identificationDocs || {};
  const identificationDocsErrors = errors.identificationDocs || {};

  const errorsMetrics = errors.metrics || [];
  const touchedMetrics = touched.metrics || [];

  const metrics = values.metrics || [];
  const metric = values.metric || {};

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Form>
      <div className="card edit-page-container mb-5">
        <div className="card-header border-bottom-0 pt-3">
          <span className="edit-page-title">
            <Link to={props.incProfUrl} className="mr-2">
              <i className="fas fa-arrow-left" />
            </Link>
            {values.name || "Organization"}
          </span>
          <span className="float-right edit-page-status ml-2">{`Organization`}</span>
        </div>
        <div className="card-body p-0 mt-2">
          {/* Basic Information */}
          <div className="card border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">
                {/* <Link to={props.incProfUrl} className="mr-2">
                  <i className="fas fa-arrow-left" />
                </Link> */}
                Basic Information
              </h5>
            </div>
            <div className="card-body">
              <div className="row mb-2">
                {/* Name */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Name
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "name") ? "is-invalid" : ""
                        }`}
                        name="name"
                        values={values.name || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Website */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Website
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "website")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="website"
                        values={values.website || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="website"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Area / Domain */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Area / Domain
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "domainOperation")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="domainOperation"
                        values={values.domainOperation || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="domainOperation"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Legal Status */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Legal Status
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "legalStatus")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="legalStatus"
                        values={values.legalStatus || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {LEGAL_STATUS_TYPES.map((data, idx) => (
                          <option key={idx} value={data.key}>
                            {data.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="legalStatus"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Year of Establishment */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Year of Establishment
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "estYear")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="estYear"
                        values={values.estYear || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {YEARS.map(yyyy => (
                          <option key={yyyy} value={yyyy}>
                            {yyyy}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="estYear"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* References */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      References
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "reference")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="reference"
                        values={values.reference || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="reference"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Subsidiary */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Subsidiary
                    </label>
                    <div className="col-lg-6">
                      <div className="custom-control custom-checkbox">
                        <Field
                          type="checkbox"
                          className="custom-control custom-control-input"
                          id="subsidiary"
                          name="subsidiary"
                          checked={values.subsidiary || false}
                          onChange={e => {
                            if (!e.target.checked) {
                              setFieldValue("parentCompany", "");
                            }
                            setFieldValue("subsidiary", e.target.checked);
                          }}
                          {...disabledField}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="subsidiary"
                        />
                        Yes
                      </div>
                      <ErrorMessage
                        component="div"
                        name="subsidiary"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Parent Company */}
                {values.subsidiary ? (
                  <div className="col-lg-6 mb-2">
                    <div className="form-group row mb-0">
                      <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                        Parent Company
                      </label>
                      <div className="col-lg-6">
                        <Field
                          type="text"
                          className={`form-control form-control-sm ${
                            getError(touched, errors, "parentCompany")
                              ? "is-invalid"
                              : ""
                          }`}
                          name="parentCompany"
                          values={values.parentCompany || ""}
                          {...disabledField}
                        />
                        <ErrorMessage
                          component="div"
                          name="parentCompany"
                          className="invalid-feedback mb-2"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <hr />
            <div className="card-body">
              <div className="row  mb-2">
                <div className="col-12 text-center">
                  <h6>
                    <label className="edit-page-label">Metrics</label>
                  </h6>
                </div>
                <div className="col-12">
                  <FieldArray
                    name="metrics"
                    render={({ insert, remove, push }) => {
                      return (
                        <Fragment>
                          <div className="table-responsive-sm table-responsive-md">
                            <table className="table table-sm table-contensed border">
                              <thead>
                                <tr>
                                  <th
                                    className="display-table-header required"
                                    scope="col"
                                  >
                                    Total Employee
                                  </th>
                                  <th
                                    className="display-table-header required"
                                    scope="col"
                                  >
                                    Female Employee
                                  </th>
                                  <th
                                    className="display-table-header w-25"
                                    scope="col"
                                  >
                                    Updated At
                                  </th>
                                  <th
                                    scope="col"
                                    className="display-table-header"
                                  />
                                </tr>
                              </thead>
                              <tbody>
                                {metrics.map((mtr, index) => {
                                  const mtrError = errorsMetrics[index] || {};
                                  const mtrTouch = touchedMetrics[index] || {};
                                  return (
                                    <Fragment key={index}>
                                      <tr>
                                        <td className="align-middle">
                                          <div
                                            className={`${
                                              getError(
                                                mtrTouch,
                                                mtrError,
                                                "employeeCount"
                                              )
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {mtr.employeeCount || ""}
                                          </div>
                                          <ErrorMessage
                                            component="div"
                                            name={`metrics[${index}].employeeCount`}
                                            className={` ${
                                              getError(
                                                mtrTouch,
                                                mtrError,
                                                "employeeCount"
                                              )
                                                ? "invalid-feedback mb-2 d-block"
                                                : "d-none"
                                            }`}
                                          />
                                        </td>
                                        <td className="align-middle">
                                          <div
                                            className={`${
                                              getError(
                                                mtrTouch,
                                                mtrError,
                                                "femaleEmployeeCount"
                                              )
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {mtr.femaleEmployeeCount || 0}
                                          </div>

                                          <ErrorMessage
                                            component="div"
                                            name={`metrics[${index}].femaleEmployeeCount`}
                                            className={` ${
                                              getError(
                                                mtrTouch,
                                                mtrError,
                                                "femaleEmployeeCount"
                                              )
                                                ? "invalid-feedback mb-2 d-block"
                                                : "d-none"
                                            }`}
                                          />
                                        </td>
                                        <td className="align-middle">
                                          {getDisplayDate(mtr.date) || null}
                                        </td>
                                        <td className="align-middle">
                                          {mtr.isNew ? (
                                            <span
                                              role="button"
                                              class="cursor-pointer ml-2"
                                              onClick={() => remove(index)}
                                            >
                                              <i
                                                class="fas fa-trash-alt fa-md action-icon-danger"
                                                datatoggle="tooltip"
                                                title="Remove"
                                              />
                                            </span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                })}

                                <tr>
                                  <td className="align-middle">
                                    <Field
                                      type="number"
                                      className={`form-control form-control-sm ${
                                        getError(
                                          touched["metric"],
                                          errors["metric"],
                                          "employeeCount"
                                        )
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`metric.employeeCount`}
                                      value={metric.employeeCount || ""}
                                      onChange={e => {
                                        const targetValue =
                                          e.target.value || null;
                                        setFieldValue(
                                          "metric.employeeCount",
                                          targetValue
                                        );
                                      }}
                                      {...disabledField}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name={`metric.employeeCount`}
                                      className="invalid-feedback mb-2"
                                    />
                                  </td>
                                  <td className="align-middle">
                                    <Field
                                      type="number"
                                      className={`form-control form-control-sm ${
                                        getError(
                                          touched["metric"],
                                          errors["metric"],
                                          "femaleEmployeeCount"
                                        )
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`metric.femaleEmployeeCount`}
                                      value={metric.femaleEmployeeCount || ""}
                                      onChange={e => {
                                        const targetValue =
                                          e.target.value || null;
                                        setFieldValue(
                                          "metric.femaleEmployeeCount",
                                          targetValue
                                        );
                                      }}
                                      {...disabledField}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name={`metric.femaleEmployeeCount`}
                                      className="invalid-feedback mb-2"
                                    />
                                  </td>
                                  <td className="align-middle">
                                    <DatePicker
                                      value={
                                        getDisplayDate(metric.date) || null
                                      }
                                      selected={metric.date || null}
                                      onChange={date => {
                                        setFieldValue(`metric.date`, date);
                                      }}
                                      utcOffset={0}
                                      placeholderText="dd-mm-yyyy"
                                      className={`form-control form-control-sm ${
                                        getError(
                                          touched["metric"],
                                          errors["metric"],
                                          "date"
                                        )
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      isClearable={true}
                                      maxDate={new Date()}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      disabled={readOnly}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name={`metric.date`}
                                      className="invalid-feedback mb-2"
                                    />
                                  </td>
                                  <td className="align-middle" />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Fragment>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <hr />
            {/* Organization Documents */}
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12 col-md-12 text-center">
                  <h6>
                    <label className="edit-page-label">
                      Organization Documents
                    </label>
                  </h6>
                </div>
                <div className="col-12 col-md-12">
                  <div className="table-responsive-sm table-responsive-md">
                    <table className="table table-sm table-contensed border">
                      <thead>
                        <tr>
                          <th className="display-table-header" scope="col">
                            #
                          </th>
                          <th className="display-table-header" scope="col">
                            Document Type
                          </th>
                          <th className="display-table-header" scope="col">
                            Name
                          </th>
                          <th className="display-table-header w-25" scope="col">
                            Action
                          </th>
                          <th
                            className="display-table-header w-25"
                            scope="col"
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {/* Company Logo */}
                        <tr>
                          <th
                            className="align-middle edit-page-label"
                            scope="row"
                          >
                            1
                          </th>
                          <td className="align-middle">Company Logo</td>
                          <td className="align-middle">
                            <div>
                              {" "}
                              {values.logo
                                ? values.logo.filename
                                : "Not Available"}
                            </div>
                            <div>
                              {" "}
                              <small className="text-info">
                                {values.uploadLogo
                                  ? `New File : "${values.uploadLogo.name}"`
                                  : null}
                              </small>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="uploadLogo"
                                  aria-describedby="uploadLogo"
                                  name="uploadLogo"
                                  onChange={e => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      setFieldValue("uploadLogo", file);
                                    }
                                  }}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="uploadLogo"
                                >
                                  {values.uploadLogo
                                    ? values.uploadLogo.name
                                    : "Choose file"}
                                </label>
                              </div>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  type="button"
                                  onClick={e => setFieldValue("uploadLogo", "")}
                                  {...disabledField}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            {values.logo ? (
                              <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={props.downloadDocument.bind(
                                  this,
                                  `${values._id}/logo/${values.logo._id}`
                                )}
                              >
                                <i className="fas fa-download mr-1" /> Logo
                              </button>
                            ) : null}
                          </td>
                        </tr>

                        {/* Org Profile */}
                        <tr>
                          <th
                            className="align-middle edit-page-label"
                            scope="row"
                          >
                            2
                          </th>
                          <td className="align-middle">
                            Company Profile (Only one Page to be uploaded)
                          </td>
                          <td className="align-middle">
                            <div>
                              {values.companyProfile
                                ? values.companyProfile.filename
                                : "Not Available"}
                            </div>
                            <div>
                              <small className="text-info">
                                {values.uploadCompanyProfile
                                  ? `New File : "${
                                      values.uploadCompanyProfile.name
                                    }"`
                                  : null}
                              </small>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="uploadCompanyProfile"
                                  aria-describedby="uploadCompanyProfile"
                                  name="uploadCompanyProfile"
                                  onChange={e => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      setFieldValue(
                                        "uploadCompanyProfile",
                                        file
                                      );
                                    }
                                  }}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="uploadCompanyProfile"
                                >
                                  {values.uploadCompanyProfile
                                    ? values.uploadCompanyProfile.name
                                    : "Choose file"}
                                </label>
                              </div>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  type="button"
                                  onClick={e =>
                                    setFieldValue("uploadCompanyProfile", "")
                                  }
                                  {...disabledField}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            {values.companyProfile ? (
                              <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={props.downloadDocument.bind(
                                  this,
                                  `${values._id}/profile/${
                                    values.companyProfile._id
                                  }`
                                )}
                              >
                                <i className="fas fa-download mr-1" /> Company
                                Profile
                              </button>
                            ) : null}
                          </td>
                        </tr>

                        {/* Company Registration Certificate */}
                        <tr>
                          <th
                            className="align-middle edit-page-label"
                            scope="row"
                          >
                            3
                          </th>
                          <td className="align-middle">
                            Company Registration Certificate
                          </td>
                          <td className="align-middle">
                            <div>
                              {values.regCertificate
                                ? values.regCertificate.filename
                                : "Not Available"}
                            </div>
                            <div>
                              <small className="text-info">
                                {values.uploadRegCertificate
                                  ? `New File : "${
                                      values.uploadRegCertificate.name
                                    }"`
                                  : null}
                              </small>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="uploadRegCertificate"
                                  aria-describedby="uploadRegCertificate"
                                  name="uploadRegCertificate"
                                  onChange={e => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      setFieldValue(
                                        "uploadRegCertificate",
                                        file
                                      );
                                    }
                                  }}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="uploadRegCertificate"
                                >
                                  {values.uploadRegCertificate
                                    ? values.uploadRegCertificate.name
                                    : "Choose file"}
                                </label>
                              </div>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  type="button"
                                  onClick={e =>
                                    setFieldValue("uploadRegCertificate", "")
                                  }
                                  {...disabledField}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            {values.regCertificate ? (
                              <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={props.downloadDocument.bind(
                                  this,
                                  `${values._id}/reg-cert/${
                                    values.regCertificate._id
                                  }`
                                )}
                              >
                                <i className="fas fa-download mr-1" />{" "}
                                Certificate
                              </button>
                            ) : null}
                          </td>
                        </tr>

                        {/* Articles Of Association */}
                        <tr>
                          <th
                            className="align-middle edit-page-label"
                            scope="row"
                          >
                            4
                          </th>
                          <td className="align-middle">
                            Articles Of Association
                          </td>
                          <td className="align-middle">
                            <div>
                              {values.articlesOfAssociation
                                ? values.articlesOfAssociation.filename
                                : "Not Available"}
                            </div>
                            <div>
                              <small className="text-info">
                                {values.uploadArticlesOfAssociation
                                  ? `New File : "${
                                      values.uploadArticlesOfAssociation.name
                                    }"`
                                  : null}
                              </small>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="uploadArticlesOfAssociation"
                                  aria-describedby="uploadArticlesOfAssociation"
                                  name="uploadArticlesOfAssociation"
                                  onChange={e => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      setFieldValue(
                                        "uploadArticlesOfAssociation",
                                        file
                                      );
                                    }
                                  }}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="uploadArticlesOfAssociation"
                                >
                                  {values.uploadArticlesOfAssociation
                                    ? values.uploadArticlesOfAssociation.name
                                    : "Choose file"}
                                </label>
                              </div>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  type="button"
                                  onClick={e =>
                                    setFieldValue(
                                      "uploadArticlesOfAssociation",
                                      ""
                                    )
                                  }
                                  {...disabledField}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            {values.articlesOfAssociation ? (
                              <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={props.downloadDocument.bind(
                                  this,
                                  `${values._id}/aoa/${
                                    values.articlesOfAssociation._id
                                  }`
                                )}
                              >
                                <i className="fas fa-download mr-1" /> Articles
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Product and Service */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Product and Service</h5>
            </div>

            <ProductsService {...props} />
          </div>

          {/* Primary Contact Information */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">
                Primary Contact Information
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                {/* Mobile */}
                <div className="col-12 col-lg-6 col-xl-6">
                  <Phones {...props} />
                </div>

                {/* Email */}
                <div className="col-12 col-lg-6 col-xl-6">
                  <Emails {...props} />
                </div>
              </div>
            </div>
          </div>

          {/* Address Information */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Addresses</h5>
            </div>
            <div className="card-body">
              <Addresses {...props} />
            </div>
          </div>

          {/* Identification Documents */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Identification Documents</h5>
            </div>
            <div className="card-body">
              {/* Pan */}
              <div className="row mb-2">
                <div className="col-lg-4">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 col-xl-4 edit-page-label required">
                      Pan
                    </label>
                    <div className="col-lg-8 col-xl-8">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(
                            identificationDocsTouched,
                            identificationDocsErrors,
                            "panNumber"
                          )
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Pan Number"
                        name="identificationDocs.panNumber"
                        value={identificationDocs.panNumber}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="identificationDocs.panNumber"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 edit-page-label">
                      Pan Scanned Copy
                    </label>
                    <div className="col-lg-7">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="identificationDocs.uploadPan"
                            name="identificationDocs.uploadPan"
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file) {
                                setFieldValue(
                                  "identificationDocs.uploadPan",
                                  file
                                );
                              }
                            }}
                            {...disabledField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="identificationDocs.uploadPan"
                          >
                            {identificationDocs.uploadPan
                              ? identificationDocs.uploadPan.name
                              : "Choose file"}
                          </label>
                        </div>
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={e =>
                              setFieldValue("identificationDocs.uploadPan", "")
                            }
                            {...disabledField}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="row mb-0">
                    <div className="col-12">
                      {identificationDocs.pan ? (
                        <button
                          className="btn btn-sm btn-info"
                          type="button"
                          onClick={() => {
                            const docId = identificationDocs.pan._id;
                            props.downloadDocument(
                              `${values._id}/id-doc/PAN/${docId}`
                            );
                          }}
                        >
                          <i className="fas fa-download mr-1" /> Pan copy
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* Gstn */}
              <div className="row mb-2">
                <div className="col-lg-4">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 col-xl-4 edit-page-label">
                      Gstn
                    </label>
                    <div className="col-lg-8 col-xl-8">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(
                            identificationDocsTouched,
                            identificationDocsErrors,
                            "gstnNumber"
                          )
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Gstn Number"
                        name="identificationDocs.gstnNumber"
                        value={identificationDocs.gstnNumber}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="identificationDocs.gstnNumber"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 edit-page-label">
                      Gstn Scanned Copy
                    </label>
                    <div className="col-lg-7">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="identificationDocs.uploadGstn"
                            aria-describedby="identificationDocs.uploadGstn"
                            name="identificationDocs.uploadGstn"
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file) {
                                setFieldValue(
                                  "identificationDocs.uploadGstn",
                                  file
                                );
                              }
                            }}
                            {...disabledField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="identificationDocs.uploadGstn"
                          >
                            {identificationDocs.uploadGstn
                              ? identificationDocs.uploadGstn.name
                              : "Choose file"}
                          </label>
                        </div>
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={e =>
                              setFieldValue("identificationDocs.uploadGstn", "")
                            }
                            {...disabledField}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="row mb-0">
                    <div className="col-12">
                      {identificationDocs.gstn ? (
                        <button
                          className="btn btn-sm btn-info"
                          type="button"
                          onClick={() => {
                            const docId = identificationDocs.gstn._id;
                            props.downloadDocument(
                              `${values._id}/id-doc/GSTIN/${docId}`
                            );
                          }}
                        >
                          <i className="fas fa-download mr-1" /> Gstn copy
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Promoters */}
            <div className="col-12 col-lg-6">
              <div className="card mt-5 border-0">
                <div className="edit-page-section-header-text">
                  <h5 className="h6 card-title mb-0">Promoters</h5>
                </div>
              </div>
              <div className="">
                <div className="table-responsive">
                  <table className="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th className="display-table-header" />
                        <th
                          className="display-table-header"
                          style={{ width: "35%" }}
                          scope="col"
                        >
                          Name
                        </th>
                        <th
                          className="display-table-header"
                          style={{ width: "10%" }}
                          scope="col"
                        >
                          Founder
                        </th>
                        <th
                          className="display-table-header required"
                          style={{ width: "35%" }}
                          scope="col"
                        >
                          Designation
                        </th>
                        <th
                          className="display-table-header required"
                          style={{ width: "20%" }}
                          scope="col"
                        >
                          Sharehold (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!values.promoters || values.promoters.length === 0 ? (
                        <tr>
                          <td className="text-center" colSpan="4">
                            No data
                          </td>
                        </tr>
                      ) : (
                        <Fragment>
                          {values.promoters &&
                            values.promoters.map((promoter, index) => {
                              const hideIndividualLink =
                                props.hideIndividualLink || readOnly || false;
                              const names = [];
                              const individual = promoter.individual || {};
                              if (individual.firstName) {
                                names.push(individual.firstName);
                              }

                              if (individual.lastName) {
                                names.push(individual.lastName);
                              }

                              const pmtrTouched =
                                (touched.promoters &&
                                  touched.promoters[index]) ||
                                {};
                              const pmtrErrors =
                                (errors.promoters && errors.promoters[index]) ||
                                {};

                              return (
                                <tr key={index}>
                                  <td />
                                  <td className="align-middle">
                                    {names.join(" ")}
                                    <br />
                                    {hideIndividualLink ? null : (
                                      <Link
                                        to={`/individuals/edit/${
                                          individual._id
                                        }?from=${
                                          props.currentLocation
                                        }&incProfUrl=${props.incProfUrl}`}
                                      >
                                        Edit
                                      </Link>
                                    )}
                                  </td>
                                  <td className="align-middle">
                                    <div className="custom-control custom-checkbox">
                                      <Field
                                        type="checkbox"
                                        className="custom-control custom-control-input"
                                        id={`values.promoters[${index}].isFounder`}
                                        name={`values.promoters[${index}].isFounder`}
                                        checked={promoter.isFounder || false}
                                        onChange={e => {
                                          setFieldValue(
                                            `promoters[${index}].isFounder`,
                                            e.target.checked
                                          );
                                        }}
                                        {...disabledField}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`values.promoters[${index}].isFounder`}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </td>
                                  <td className="align-middle">
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        getError(
                                          pmtrTouched,
                                          pmtrErrors,
                                          "designation"
                                        )
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`promoters[${index}].designation`}
                                      value={promoter.designation || ""}
                                      {...disabledField}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name={`promoters[${index}].designation`}
                                      className="invalid-feedback mb-2"
                                    />
                                  </td>
                                  <td className="align-middle">
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        getError(
                                          pmtrTouched,
                                          pmtrErrors,
                                          "shareholding"
                                        )
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`promoters[${index}].shareholding`}
                                      value={promoter.shareholding || ""}
                                      {...disabledField}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name={`promoters[${index}].shareholding`}
                                      className="invalid-feedback mb-2"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card mt-5 border-0">
                <div className="edit-page-section-header-text">
                  <h5 className="h6 card-title mb-0">Financial Information</h5>
                </div>
              </div>
              <div className="">
                <FieldArray
                  name="financials"
                  render={({ insert, remove, push }) => {
                    return (
                      <Fragment>
                        <div className="table-responsive">
                          <table className="table table-sm mb-0">
                            <thead>
                              <tr>
                                <th
                                  className="display-table-header"
                                  style={{ width: "30%" }}
                                  scope="col"
                                >
                                  Financial Year
                                </th>
                                <th
                                  className="display-table-header"
                                  style={{ width: "30%" }}
                                  scope="col"
                                >
                                  Authorized Capital
                                </th>
                                <th
                                  className="display-table-header"
                                  style={{ width: "30%" }}
                                  scope="col"
                                >
                                  Annual Turnover
                                </th>
                                <th className="display-table-header" />
                              </tr>
                            </thead>
                            <tbody>
                              {!values.financials ||
                              values.financials.length === 0 ? (
                                <tr>
                                  <td className="text-center" colSpan="4">
                                    No data
                                  </td>
                                </tr>
                              ) : (
                                <Fragment>
                                  {values.financials.map((finance, index) => {
                                    const financeTouched =
                                      (touched.financials &&
                                        touched.financials[index]) ||
                                      {};
                                    const financeErrors =
                                      (errors.financials &&
                                        errors.financials[index]) ||
                                      {};

                                    return (
                                      <tr key={index}>
                                        <td className="align-middle">
                                          <Field
                                            component="select"
                                            className={`custom-select custom-select-sm ${
                                              getError(
                                                financeTouched,
                                                financeErrors,
                                                "financialYear"
                                              )
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            name={`financials[${index}].financialYear`}
                                            value={finance.financialYear}
                                            {...disabledField}
                                          >
                                            <option value="">Select...</option>
                                            {fnYear.map(yyyy => {
                                              return (
                                                <option key={yyyy} value={yyyy}>
                                                  {yyyy}
                                                </option>
                                              );
                                            })}
                                          </Field>

                                          <ErrorMessage
                                            component="div"
                                            name={`financials[${index}].financialYear`}
                                            className="invalid-feedback mb-2"
                                          />
                                        </td>
                                        <td className="align-middle">
                                          <Field
                                            type="number"
                                            className={`form-control form-control-sm ${
                                              getError(
                                                financeTouched,
                                                financeErrors,
                                                "authorizedCapital"
                                              )
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            name={`financials[${index}].authorizedCapital`}
                                            value={finance.authorizedCapital}
                                            {...disabledField}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name={`financials[${index}].authorizedCapital`}
                                            className="invalid-feedback mb-2"
                                          />
                                        </td>
                                        <td className="align-middle">
                                          <Field
                                            type="number"
                                            className={`form-control form-control-sm ${
                                              getError(
                                                financeTouched,
                                                financeErrors,
                                                "annualTurnover"
                                              )
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            name={`financials[${index}].annualTurnover`}
                                            value={finance.annualTurnover}
                                            {...disabledField}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name={`financials[${index}].annualTurnover`}
                                            className="invalid-feedback mb-2"
                                          />
                                        </td>
                                        <td>
                                          {values.financials.length > 1 ? (
                                            <span
                                              role="button"
                                              className="cursor-pointer ml-2"
                                            >
                                              <i
                                                className="fas fa-trash-alt fa-md action-icon-danger"
                                                datatoggle="tooltip"
                                                title="Remove"
                                                onClick={() => remove(index)}
                                              />
                                            </span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="mt-2">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={() => {
                              push({
                                annualTurnover: 0,
                                authorizedCapital: 0,
                                financialYear: ""
                              });
                            }}
                            {...disabledField}
                          >
                            Add More
                          </button>
                        </div>
                      </Fragment>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer text-muted mt-5 p-4">
          {/* Update button */}
          <div className="row no-gutters">
            <div className="col-12 text-center">
              {readOnly ? null : (
                <Can do="UPDATE" on="Organization">
                  <button
                    className="btn btn-sm btn-primary ml-md-5"
                    type="submit"
                  >
                    Update
                  </button>
                </Can>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const OrganizationForm = props => {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={props.organization}
        validationSchema={ORGANIZATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          props.handleSubmit(values);
        }}
        render={renderProps => <CustomForm {...renderProps} {...props} />}
      />
    </div>
  );
};

export default OrganizationForm;
