import React, { useEffect, useState } from "react";
import HttpUtil from "./HttpUtil";

function handleSubmit(values, setApiStatus) {
  setApiStatus({ status: "loading" });
  const url = "/api/v1/auth/password-reset";

  const headers = { "Content-Type": "application/json" };

  HttpUtil.post(
    url,
    headers,
    values,
    data => setApiStatus({ status: "success" }),
    (data, status) => setApiStatus({ status: "error", message: data.message }),
    error => setApiStatus({ status: "error", message: error.toString() })
  );
}

function ResetPassword() {
  const [token, setToken] = useState("");
  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: ""
  });
  const [apiStatus, setApiStatus] = useState({ status: "idle" });

  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]); // Get the part after '?'
    const token = params.get("token");
    setToken(token);
  }, []);

  return (
    <div className="main h-100 w-100">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <p className="lead">Enter your new password</p>
              </div>

              {apiStatus.status === "error" ? (
                <div className="alert alert-danger" role="alert">
                  {apiStatus.message}
                </div>
              ) : null}

              <div className="card">
                <div className="card-body">
                  <div className="m-sm-4">
                    {apiStatus.status === "success" ? (
                      <div className="text-center">
                        <div className="alert alert-success text-center">
                          New password updated successfully!
                        </div>
                        <a href="/#/login" className="btn btn-md btn-primary">
                          Go to Login
                        </a>
                      </div>
                    ) : null}

                    {apiStatus.status !== "success" ? (
                      <form>
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={resetPassword.password}
                            autoComplete="on"
                            onChange={e => {
                              setResetPassword({
                                ...resetPassword,
                                password: e.target.value
                              });
                            }}
                          />
                        </div>

                        <div className="form-group">
                          <label>Confirm Password</label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            name="confirmPassword"
                            placeholder="Enter your password"
                            value={resetPassword.confirmPassword}
                            autoComplete="on"
                            onChange={e => {
                              setResetPassword({
                                ...resetPassword,
                                confirmPassword: e.target.value
                              });
                            }}
                          />
                        </div>

                        <div
                          className="text-sm leading-5"
                          style={{ marginTop: "-14px" }}
                        >
                          <a
                            href="/#/login"
                            className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                          >
                            Login
                          </a>
                        </div>
                        <div className="text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-md btn-primary"
                            onClick={() => {
                              const payload = {
                                password: resetPassword.password,
                                confirmPassword: resetPassword.confirmPassword,
                                token: token
                              };
                              handleSubmit(payload, setApiStatus);
                            }}
                          >
                            Reset password
                          </button>
                        </div>
                      </form>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
