import React, { useState } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "./HttpUtil";

function SuccessMessage() {
  return (
    <div className="card mt-5">
      <div className="card-body">
        <div className="m-sm-4">
          <div className="text-center mt-4">
            <p className="lead">Password reset link sent successfully!</p>

            <p className="text-center bg-white rounded-md  p-7 ">
              An email has been sent to your email address with an link to reset
              your password. If it doesn't appear within a few minutes, then
              please check your spam folder.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function handleSubmit(values, setApiStatus) {
  setApiStatus({ status: "loading" });
  const url = "/api/v1/auth/password-reset-link";

  const headers = { "Content-Type": "application/json" };

  HttpUtil.post(
    url,
    headers,
    values,
    data => setApiStatus({ status: "success" }),
    (data, status) => setApiStatus({ status: "error", message: data.message }),
    error => setApiStatus({ status: "error", message: error.toString() })
  );
}

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [apiStatus, setApiStatus] = useState({ status: "idle" });

  return (
    <div className="main h-100 w-100">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              {apiStatus.status === "success" ? <SuccessMessage /> : null}

              {apiStatus.status !== "success" ? (
                <div className="text-center mt-4">
                  <p className="lead">Enter your email or Username</p>
                </div>
              ) : null}

              {apiStatus.status === "error" ? (
                <div className="alert alert-danger" role="alert">
                  {apiStatus.message}
                </div>
              ) : null}

              {apiStatus.status !== "success" ? (
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <form>
                        <div className="form-group">
                          <label>Email or Username</label>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="email"
                            placeholder="Enter your email or username"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>

                        <div
                          className="text-sm leading-5"
                          style={{ marginTop: "-14px" }}
                        >
                          <Link
                            to="/login"
                            className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                          >
                            Login
                          </Link>
                        </div>
                        <div className="text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-md btn-primary"
                            onClick={() =>
                              handleSubmit(
                                { email: email.trim(), username: email.trim() },
                                setApiStatus
                              )
                            }
                          >
                            Verify Email
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
